<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">หน้าแรก</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-remove"></i></button>
        </div>
      </div>
      <div class="card-body">
        <div class="col-md-12 col-sm-1">
          <div class="row">
                <div class="col-lg-3 col-6">
                  <!-- small card -->
                  <div class="small-box bg-info shadow " @click.prevent="redirectURL('customers')">
                    <div class="inner shadow">
                      <h3 class="text-center">{{this.allCustomer}} รายการ</h3>
                      <p class="text-center" style="font-size: calc(90%);">จำนวนลูกค้าทั้งสิ้น</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-user"></i>
                    </div>
                    <a href="#"  @click.prevent="redirectURL('customers')" class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
                <!-- ./col -->
                <div class="col-lg-3 col-6">
                  <!-- small card -->
                  <div class="small-box bg-success shadow" @click.prevent="redirectURL('customers')">
                    <div class="inner">
                      <h3 class="text-center">{{this.allNewCustomer.COUNT}}</h3>
                      <p class="text-center" style="font-size: calc(90%);">ลูกค้าใหม่ที่สร้างในเดือนนี้</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-user-plus"></i>
                    </div>
                    <a href="#"  @click.prevent="redirectURL('customers')" class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
                <!-- ./col -->
                <div class="col-lg-3 col-6">
                  <!-- small card -->
                  <div class="small-box bg-warning shadow" @click.prevent="redirectURL('customerorderreport')">
                    <div class="inner">
                      <h3 class="text-center">{{this.countMouthAppoint.COUNT}}</h3>
                      <p class="text-center" style="font-size: calc(90%);">จำนวนลูกค้าที่นัดในเดือนนี้</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-plus-circle"></i>
                    </div>
                    <a href="#"  @click.prevent="redirectURL('customerorderreport')" class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
                <!-- ./col -->
                <div class="col-lg-3 col-6">
                  <!-- small card -->
                  <div class="small-box bg-purple shadow" @click.prevent="redirectURL('customerorderreport')">
                    <div class="inner">
                      <h3 class="text-center">{{this.countMouthNew.COUNT}}</h3>
                      <p class="text-center" style="font-size: calc(90%);">จำนวนลูกค้าที่นัดใหม่ในเดือนนี้</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-hand-holding-usd"></i>
                    </div>
                    <a href="#"  @click.prevent="redirectURL('customerorderreport')" class="small-box-footer">
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
                <!-- ./col -->
              </div>
          <div class="row">
            <div class="col-md-6">
              <label ><i class="fas fa-user-circle"></i>  รายชื่อลูกค้านัดหมายในเดือนนี้</label>
                <div class="card-body table-responsive p-0" style="height: 550px;">
                <table class="table table-head-fixed table-hover table-bordered">
                  <thead class="thead-light text-center">
                  <tr>
                    <th scope="col" style="background-color: rgb(197,196,196)">#</th>
                    <th v-for="ths in th" :key="ths" style="background-color: rgb(197,196,196)">
                      {{ths}}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(datas,index) in dataMouthAppoint" :key="datas.id">
                    <th scope="row" style="width: 5px;font-size: 12px" >{{index+1}}</th>
                    <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{customerSights(datas.customersights)}}</td>
                    <td style="width: 7%;font-size: 12px">{{datas.custNo }}</td>
                    <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.custTitle}}  {{datas.custName}} {{datas.custSurname}}</td>
                    <td style="font-size: 12px">{{datas.custTel }}</td>
                    <td style="font-size: 12px">{{datas.custMobile }}</td>
                  </tr>
                  <tr v-show="dataMouthAppoint.length === 0"><td colspan="6" class="text-center">ไม่มีรายการ</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <label ><i class="fas fa-hand-holding-usd "></i>  รายการล่าสุดที่ดำเนินการในเดือนนี้</label>
                <div class="card-body table-responsive p-0" style="height: 550px;">
                <table class="table table-head-fixed table-hover table-bordered">
                  <thead class="thead-light text-center">
                  <tr>
                    <th scope="col" style="background-color: rgb(197,196,196)">#</th>
                    <th v-for="ths in th2" :key="ths" style="background-color: rgb(197,196,196)">
                      {{ths}}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(datas,index) in dataMouthOrder" :key="datas.id">
                    <th scope="row" style="width: 5px;font-size: 12px" >{{index+1}}</th>
                    <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.sightDate | myDateThs}}</td>
                    <td style="width: 7%;font-size: 12px">{{customerNo(datas.customer) }}</td>
                    <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{customerName(datas.customer)}}</td>
                    <td style="font-size: 12px">{{datas.lensLensType }}</td>
                    <td style="font-size: 12px">{{datas.lensColor }}</td>
                    <td style="font-size: 12px">{{datas.lensFrame }}</td>
                  </tr>
                  <tr v-show="dataMouthOrder.length === 0"><td colspan="7" class="text-center">ไม่มีรายการ</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer bg-gray-light">
        <div class="col-md-12 col-sm-1">
            <div class="col-md-12 text-center">
              <p ><i class="fas fa-copyright"></i> Power by ธีระการแว่น 2022</p>
<!--              <div class="col-md-12">-->
<!--                <pre class="col-md-12 form-control-plaintext" ></pre>-->
<!--                <a href="#" class="col-md-12 form-control-plaintext"></a>-->
<!--              </div>-->
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>

import axios from "axios";
import moment from "moment";

export default {
  name: 'home',
  data(){
    return{
      allCustomer:'',
      allNewCustomer:'',
      countMouthAppoint:'',
      countMouthNew:'',
      th:['วันที่','รหัสลูกค้า','ชื่อ-นามสกุล','เบอร์โทร','มือถือ'],
      th2:['วันที่','รหัสลูกค้า','ชื่อ-นามสกุล','LensType','สี','lensFrame'],
      dataMouthAppoint:'',
      dataMouthOrder:'',


    }
  },

  mounted() {
    this.getResults()
  },

  methods:{
    async getResults(){
      await axios.get('api/customer/customerDashboard')
          .then(response => {
            if (response.data.success === true)
              this.allCustomer = response.data.allCustomer
              this.allNewCustomer = response.data.allNewCustomer
              this.countMouthAppoint = response.data.countMouthAppoint
              this.countMouthNew = response.data.countMouthNew
          }).catch((error)=>{
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
      await axios.get('api/customer/customerDashboardDetail')
          .then(response => {
            if (response.data.success === true)
            this.dataMouthAppoint = response.data.dataMouthAppoint
            this.dataMouthOrder = response.data.dataMouthOrder
          }).catch((error)=>{
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },

    customerSights(data){
      if (data){
        let date = data[0].sightAppoint
        return moment(date).add(543, 'years').locale('th').format('ll');
      }


    },

    customerName(data){
      if (data){
        let cust = data
        let name = cust.custTitle === 'ไม่ระบุ' ? '' : cust.custTitle
        return   name+'  '+cust.custName+' '+cust.custSurname
      }


    },
    customerNo(data){
      if (data){
        let cust = data
        return  cust.custNo
      }


    },

    redirectURL(data){
      this.$router.push('/'+data)
    },

  }
}
</script>
